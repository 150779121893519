<template>
  <div class="recipes">
    <div class="container">
      <app-modal-filter />
      <div class="recipes__nav">
        <locale-router-link
          to=""
          class="crumbs-link main-page-link"
        >
          {{ $t("home") }}
        </locale-router-link>
        <!-- <locale-router-link
          to="cooking"
          class="crumbs-link cooking-with-magnum"
          >{{ $t("cookingWithMagnum") }}
        </locale-router-link> -->
        <a class="crumbs-link recipes-link"> {{ $t("recipes.title") }}</a>
      </div>

      <div class="recipes__main">
        <h1 class="recipes__title">{{ $t("recipes.title") }}</h1>
        <app-recipes-slider />
      </div>

      <div class="recipes__catalog">
        <div class="recipes__catalog__filters">
          <button
            @click="openFilter()"
            class="recipes__catalog__filters-filter"
          >
            <img
              src="@/assets/img/icons/filter-gray.svg"
              alt=""
            />
            {{ $t("buttons.filter") }}
          </button>
          <!-- <button class="recipes__catalog__filters-select">{{ $t("buttons.new") }}</button> -->
        </div>
        <div
          v-if="recipes.length"
          class="recipes__catalog__items"
        >
          <app-recipes-item
            v-for="(item, index) in recipes"
            :key="item.id"
            :class="{ big: checkItemSize(index) }"
            :item="item"
          />
        </div>
        <div v-else>
          <p>нет рецептов</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllRecipes, getAllRecipesByFilters } from "@/api/recipes"
import { actionTypes } from "@/store"

import AppRecipesItem from "@/components/recipes/AppRecipesItem.vue"
import AppRecipesSlider from "@/components/recipes/AppRecipesSlider.vue"
import AppModalFilter from "@/components/modal/AppModalFilter.vue"
import { mapState, mapActions } from "vuex"

export default {
  components: {
    AppRecipesItem,
    AppRecipesSlider,
    AppModalFilter,
  },
  name: "PageRecipes",
  data() {
    return {
      recipes: [],
      filterActive: false,
    }
  },
  watch: {
    filters: {
      handler() {
        this.loadAllRecipesByFilter()
      },
    },
  },
  mounted() {
    if (this.filters.length) {
      this.loadAllRecipesByFilter()
    } else {
      this.loadAllRecipes()
    }
  },
  methods: {
    async loadAllRecipes() {
      const response = await getAllRecipes()
      this.recipes = response.data
    },
    async loadAllRecipesByFilter() {
      const response = await getAllRecipesByFilters(
        {
          difficulty: this.filters.find((e) => e.type === "difficulty")
            ? this.filters.find((e) => e.type === "difficulty").value
            : null,
          ETA: this.filters.find((e) => e.type === "ETA")
            ? this.filters.find((e) => e.type === "ETA").value
            : null,
        },
        { field: "id", sortType: "asc" },
      )
      this.recipes = response.data
    },
    checkItemSize(e) {
      const index = e + 1
      return index % 5 === 1 || index % 5 === 2
    },
    ...mapActions({ openFilter: actionTypes.changeOpenFilter }),
  },
  computed: {
    ...mapState(["isFilterActive"]),
    ...mapState("recipesModule", ["filters"]),
  },
}
</script>

<style
  lang="scss"
  scoped
>
@media screen and (max-width: 992px) {
  .recipe-item {
    margin-left: 0;
  }
}
</style>
