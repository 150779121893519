<template>
  <div
    v-if="isFilterActive"
    class="modal"
    @click="closeFilter"
  >
    <div
      @click.stop
      class="filter__modal"
    >
      <div class="filter__modal-box">
        <div
          @click="closeFilter"
          class="back"
        >
          <svg
            width="7"
            height="16"
            viewBox="0 0 7 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 14.5697L1.0617 16L7 8L1.0617 0L0 1.4303L4.87661 8L0 14.5697Z"
              fill="currentColor"
            />
          </svg>
          <p>{{ $t("modals.back") }}</p>
        </div>
        <div class="filter__modal__name">
          <p>{{ $t("buttons.filter") }}</p>
          <img
            @click="closeFilter"
            src="@/assets/img/icons/close.svg"
            alt=""
          />
        </div>
        <div class="filter__modal__options">
          <div
            v-for="(filter, index) in filters"
            :key="index"
          >
            <p class="options__name">{{ filter.name }}</p>
            <div class="options__buttons">
              <button
                v-for="(btn, index) in filter.data"
                :key="index"
                @click="selectFilter({ btn: btn, filter: filter })"
                :class="{ active: isSelectFilter({ filter: filter, btn: btn }) }"
              >
                {{ btn.label }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal__button">
        <div class="modal__button-box">
          <button
            class="modal__button__passive"
            @click="resetFilter"
          >
            Сбросить фильтры
          </button>
          <button
            class="modal__button__active"
            @click="applyFilter"
          >
            Применить фильтры
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { actionTypes } from "@/store"
import { actionTypes as actionTypesRecipes } from "@/store/modules/recipes"

export default {
  data() {
    return {
      filters: [
        {
          id: 1,
          name: "Сложность:",
          selectValue: null,
          data: [
            {
              label: "легкий",
              value: 1,
              type: "difficulty",
            },
            {
              label: "средний",
              value: 2,
              type: "difficulty",
            },
            {
              label: "профи",
              value: 3,
              type: "difficulty",
            },
          ],
        },
        {
          id: 2,
          name: "Время приготовления:",
          selectValue: null,
          data: [
            {
              label: "до 15 минут",
              value: ["00:00:00", "00:15:00"],
              type: "ETA",
            },
            {
              label: "до 30 минут",
              type: "ETA",
              value: ["00:00:00", "00:30:00"],
            },
          ],
        },
        // {
        //   id: 3,
        //   name: "Дополнительно:",
        //   label: "ETA",
        //   data: [
        //     {
        //       label: "новинки",
        //       value: "новинки",
        //     },
        //   ],
        // },
      ],
      selectFilters: [],
    }
  },
  computed: {
    ...mapState(["isFilterActive"]),
  },
  methods: {
    ...mapActions({ closeFilter: actionTypes.changeOpenFilter }),
    ...mapActions("recipesModule", {
      getRecipesFilters: actionTypesRecipes.getRecipesFilters,
    }),
    selectFilter(item) {
      this.filters.find((e) => e.id === item.filter.id).selectValue = item.btn
    },
    resetFilter() {
      this.filters.forEach((e) => {
        e.selectValue = null
      })
      this.selectFilters = []
      this.getRecipesFilters(this.selectFilters)
    },
    applyFilter() {
      this.selectFilters = []
      this.filters.forEach((e) => {
        if (e.selectValue) {
          this.selectFilters.push(e.selectValue)
        }
      })
      this.getRecipesFilters(this.selectFilters)
      this.closeFilter()
    },
    isSelectFilter(item) {
      return item?.filter?.selectValue?.value === item?.btn?.value
    },
  },
}
</script>

<style></style>
