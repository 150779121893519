<template>
  <div class="recipe-item">
    <locale-router-link :to="`/recipes/${item.id}`">
      <img
        :src="getItemImage"
        class="recipe-item__image"
      />
      <div class="recipe-item__content">
        <p class="recipe-item__content-date">{{ getDateFormat(item.attributes.publishedAt) }}</p>
        <h2 class="recipe-item__content-title">{{ item.attributes.title }}</h2>
        <p class="recipe-item__content-time">{{ getTime(time) }} минут</p>
      </div>
    </locale-router-link>
  </div>
</template>

<script>
import moment from "moment"

export default {
  name: "AppRecipesItem",

  props: {
    item: {
      type: Object,
      default: () => {},
      required: true,
    },
  },

  data() {
    return {}
  },
  computed: {
    getItemImage() {
      if (this.item.attributes?.image?.data) {
        return this.$helpers.getAbsolutePath(this.item.attributes?.image?.data?.attributes?.url)
      }
      return require("@/" + "assets/img/icons/placeholder.svg")
    },
  },

  methods: {
    getDateFormat(date) {
      return moment(date).locale(this.$i18n.locale).format("LL")
    },
    getTime(time) {
      time = this.item.attributes.ETA.split(":")
      const result = parseInt(time[1], 10)
      return result
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.big {
  width: 48%;
  max-width: 580px !important;
  & + & {
    margin-left: 2%;
  }
  img {
    height: 290px;
    @media (max-width: 500px) {
      height: 144px !important;
    }
  }

  @media screen and (max-width: 1000px) {
    margin-left: 0 !important;
    img {
      height: 190px;
    }
  }

  @media screen and (max-width: 575px) {
    width: 100%;
  }
}
</style>
