import axios from "@/plugins/axios"
import qs from "qs"

export const getAllRecipes = async (page = 1, pageSize = 100, excludeId = null) => {
  const queryFilters = {}
  if (excludeId) {
    queryFilters.filters = {
      id: {
        $ne: excludeId,
      },
    }
  }

  const query = qs.stringify(
    {
      sort: ["id:asc"],
      pagination: {
        page,
        pageSize,
      },
      populate: {
        image: "*",
        localizations: "*",
        steps: {
          populate: "*",
        },
        recipe_details: {
          populate: "*",
          subcategory: {
            populate: "*",
          },
        },
        nutrition_facts: {
          populate: "*",
        },
      },
      ...queryFilters,
    },
    {
      encodeValuesOnly: true,
    },
  )
  const response = await axios.get(`/recipes?${query}`)
  return response?.data
}

export const getRecipeById = async (id) => {
  const query = qs.stringify(
    {
      populate: {
        image: "*",
        localizations: "*",
        products: {
          populate: "*",
        },
        steps: {
          populate: "*",
        },
        recipe_details: {
          populate: "*",
          subcategory: {
            populate: "*",
          },
        },
        nutrition_facts: {
          populate: "*",
        },
      },
    },
    {
      encodeValuesOnly: true,
    },
  )
  const response = await axios.get(`/recipes/${id}?${query}`)
  return response?.data
}

export const getAllRecipesByFilters = async (filters, sortBy, pagination, cityId) => {
  const { difficulty, ETA } = filters
  if (!cityId) {
    cityId =
      localStorage.key("selectCity") && localStorage.getItem("selectCity") !== "undefined"
        ? JSON.parse(localStorage.getItem("selectCity"))?.id
        : 2
  }

  let queryFilters = { filters: {} }

  if (difficulty) {
    queryFilters.filters.difficulty = {
      $in: difficulty,
    }
  }

  if (!difficulty && !cityId) {
    queryFilters.filters.difficulty = {
      $notNull: true,
    }
  }
  if (ETA) {
    queryFilters.filters.ETA = {
      $between: ETA,
    }
  }

  if (!ETA && !cityId) {
    queryFilters.filters.ETA = {
      $notNull: true,
    }
  }

  let sort = {}

  if (sortBy) {
    sort = { sort: [`${sortBy.field}:${sortBy.sortType}`] }
    // sort: [`${sortBy.field}:${sortBy.sortType}`],
  } else {
    sort = { sort: ["id:asc"] }
  }

  const query = qs.stringify(
    {
      pagination: {
        page: pagination?.page || 1,
        pageSize: pagination?.pageSize || 1500,
      },
      populate: {
        image: "*",
        localizations: "*",
        steps: {
          populate: "*",
        },
        recipe_details: {
          populate: "*",
          subcategory: {
            populate: "*",
          },
        },
        nutrition_facts: {
          populate: "*",
        },
      },
      ...sort,
      ...queryFilters,
    },
    {
      encodeValuesOnly: true,
    },
  )

  const response = await axios.get(`/recipes?${query}`)
  return response?.data
}
