<template>
  <div class="recipes-banner">
    <swiper
      v-if="banners.length"
      ref="recipesSwiperRef"
      class="recipes-banner__slider"
      :options="swiperOption"
    >
      <swiper-slide
        v-for="(banner, index) in banners"
        :key="index"
      >
        <div
          class="recipes-banner__slide"
          :style="{ backgroundImage: `url(${getBannerImage(banner)})` }"
        >
          <div class="recipes-banner__inner">
            <div class="recipes-banner__content">
              <div class="recipes-banner__header">
                <p class="recipes-banner__date">{{ getDate(banner) }}</p>
              </div>
              <h3 class="recipes-banner__title">
                {{ banner.attributes.header }}
              </h3>
              <div class="recipes-banner__buttons">
                <a
                  :href="banner.attributes.link"
                  class="recipes-banner__btn"
                >
                  {{ $t("more") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="recipes-banner__arrows">
      <button
        class="recipes-banner__prev"
        @click="prevSlide"
      >
        <img
          src="@/assets/img/icons/prev-arrow-gray.png"
          alt=""
        />
      </button>
      <button
        class="recipes-banner__next"
        @click="nextSlide"
      >
        <img
          src="@/assets/img/icons/next-arrow-gray.png"
          alt=""
        />
      </button>
    </div>
    <div class="slider-pagination-wrapper">
      <div class="pagination__custom"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"

import { getBanners } from "@/api"
export default {
  name: "AppRecipesSlider",
  props: {},
  data() {
    return {
      banners: [],
      swiperOption: {
        autoplay: {
          delay: 10000,
        },
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
        lazy: true,
        slideTo: 1,
        slidesPerView: 1,
        spaceBetween: 20,
        initialSlide: 1,
        centeredSlides: false,
        loop: true,
        responsive: true,
        allowTouchMove: true,
        focusOnSelect: false,
        pagination: {
          el: ".pagination__custom",
          clickable: true,
          renderBullet: function (index, className) {
            return `<div class="wrapper ${className}">
              <div class="pie spinner"></div>
              <div class="pie filler"></div>
              <div class="mask"></div>
            </div>`
          },
        },
        breakpoints: {
          768: {
            allowTouchMove: false,
          },
        },
      },
      localMonth: {
        января: "қаңтар",
        февраля: "ақпан",
        марта: "наурыз",
        апреля: "сәуір",
        мая: "мамыр",
        июня: "маусым",
        июля: "шілде",
        августа: "тамыз",
        сентября: "қыркүйек",
        октября: "қазан",
        ноября: "қараша",
      },
    }
  },
  watch: {
    selectedCity: {
      deep: true,
      handler() {
        getBanners(this.selectedCity.id, "article").then((res) => {
          this.banners = res
        })
      },
    },
  },
  created() {
    getBanners(this.selectedCity.id, "article").then((res) => {
      this.banners = res
    })
  },
  computed: {
    swiper() {
      return this.$refs?.recipesSwiperRef
    },
    ...mapState("cityModule", ["selectedCity"]),
  },
  methods: {
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev()
    },
    nextSlide() {
      this.swiper.swiperInstance.slideNext()
    },
    getDate(item) {
      const dateFromStr = new Date(item.attributes.publishedAt)
      let dateRU = new Intl.DateTimeFormat("ru-Ru", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(dateFromStr)
      let date = dateRU.split(" ")
      let dateKZ = date[0] + " " + this.localMonth[date[1]] + " " + date[2]
      if (this.$i18n.locale === "kz") {
        return dateKZ
      } else {
        return dateRU
      }
    },
    getBannerImage(banner) {
      const placeHolderImageUrl =
        "https://placeholder.pics/svg/288x380/B7FF7D/6C6D73-79E858/no%20moblie%20image"
      if (this.$isMobile()) {
        return banner.attributes?.image_mob?.data?.attributes?.url
          ? this.$helpers.getAbsolutePath(banner.attributes?.image_mob?.data?.attributes?.url)
          : placeHolderImageUrl
      }
      return banner.attributes?.image?.data?.attributes?.url
        ? this.$helpers.getAbsolutePath(banner.attributes?.image?.data?.attributes?.url)
        : placeHolderImageUrl
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.pagination__custom,
.pagination__custom-production {
  text-align: center;
}
.slider-pagination-wrapper {
  display: none;
}

@media (max-width: 600px) {
  .recipes__catalog {
    margin-top: 20px;
  }
  .slider-pagination-wrapper {
    margin-top: 10px;
    display: block;
  }
}
</style>
